var $ = require("jquery");

import { anchorSmoothScroll } from "./modules/anchorSmoothScroll";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import { header } from "./modules/header";
import { mwwpErrorScroll } from "./modules/mwwpErrorScroll";

$(function () {
  anchorSmoothScroll();
  header();
  mwwpErrorScroll();
  hamburgerMenu();
});
