import $ from "jquery";

export const header = () => {
  const body = $("body");
  const header = $("#header");
  const mvHeight = $("#mv").innerHeight();

  window.addEventListener("scroll", function () {
    const scroll = window.scrollY;

    if (scroll > mvHeight) {
      body.addClass("is-scrolled");
    } else {
      body.removeClass("is-scrolled");
    }
  });
};
