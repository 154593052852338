import $ from "jquery";

export const hamburgerMenu = () => {
  const body = $("body");

  $("#hamburger-btn").on("click", function () {
    body.toggleClass("is-hamburger-menu-open");
  });

  const hamburgerMenuCloseBtn = $(".js-hamburger-menu-close");
  hamburgerMenuCloseBtn.on("click", hamburgerMenuClose);

  const hamburgerMenuLinks = $(".js-hamburger-menu a");
  hamburgerMenuLinks.on("click", hamburgerMenuClose);

  function hamburgerMenuClose() {
    body.removeClass("is-hamburger-menu-open");
  }
};
