import $ from "jquery";

export const mwwpErrorScroll = () => {
  const headerHeight = $("#header").height();

  if ($(".error")[0]) {
    var errorEl = $(".mw_wp_form").eq(0);
    var position = errorEl.parent().offset().top - headerHeight;
    $("body,html").delay(200).animate({ scrollTop: position }, 600, "swing");
  }
};
