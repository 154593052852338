import $ from "jquery";

export const anchorSmoothScroll = () => {
  var urlHash = location.hash;
  if (urlHash) {
    $("body,html").stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var headerHeight = $("#header").height();
      var position = target.offset().top - headerHeight;
      $("body,html").stop().animate({ scrollTop: position }, 500);
    }, 500);
  }
  $('a[href^="#"]').on("click", function () {
    var headerHeight = $("#header").height();
    var href = $(this).attr("href");
    var target = $(href);
    var position = target.offset().top - headerHeight;
    $("body,html").stop().animate({ scrollTop: position }, 500);
  });
};
